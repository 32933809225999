<template>
  <div class="MissingThings">
    <PageLoading v-if="isLoading" />

    <div
      class="InnerWrap"
      v-if="!isLoading">
      <MissingItem
        :text="mixWB('BUILDER_NAME')"
        name="builder"
        :isDone="!!builder.fullName"
        @item-click="onItemClick" />
      <MissingItem
        :text="mixWB('BUILDER_PHONE')"
        name="builder"
        :isDone="!!builder.phoneNumber"
        @item-click="onItemClick" />
      <MissingItem
        :text="mixWB('BUILDER_EMAIL')"
        name="builder"
        :isDone="!!builder.email"
        @item-click="onItemClick" />
      <MissingItem
        :text="mixWB('CONTENT_OF_OFFER')"
        name="offer"
        :isDone="tenderDetails"
        @item-click="onItemClick" />
      <MissingItem
        :text="mixWB('START_AND_DEADLINE')"
        name="offer"
        :isDone="tenderDates"
        @item-click="onItemClick" />
    </div>

    <Dialog
      :isShowing="showBuilderModal"
      :useComponent="BuilderModal"
      :componentProps="{}"
      @builder-updated="onDialogClose"
      @close="onDialogClose" />

    <Dialog
      :isShowing="showTenderDetailsModal"
      :useComponent="TenderDetailsModal"
      :componentProps="{}"
      @tender-details-selected="onDialogClose"
      @close="onDialogClose" />
  </div>
</template>

<script>
import PageLoading from '@/components/PDF/PageLoading.vue'
import TenderDetailsModal from '@/components/PDF/Modals/TenderDetailsModal.vue'
import BuilderModal from '@/components/PDF/Modals/BuilderModal.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import MissingItem from '@/components/PDF/MissingItem.vue'
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'

export default {
  name: 'MissingThings',
  data() {
    return {
      BuilderModal,
      TenderDetailsModal,
      isLoading: true,
      showBuilderModal: false,
      showTenderDetailsModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'metaData',
      'buildings',
      'selectedBuildings',
      'addressImages',
      'currentScreeningTenderDetails',
      'addressImagesLoaded',
      'currentScreeningMetaDataLoaded',
      'currentScreeningBuildingsLoaded',
      'currentScreeningLoaded',
    ]),
    builder() {
      return this.metaData.builder || {}
    },
    tenderDetails() {
      if (!this.currentScreeningTenderDetails) {
        return false
      }

      if (
        !this.currentScreeningTenderDetails.selectedQuestionIDs.length
        && !this.currentScreeningTenderDetails.otherTexts.length
      ) {
        return false
      }

      return true
    },
    tenderDates() {
      if (!this.currentScreeningTenderDetails) {
        return false
      }

      if (
        !this.currentScreeningTenderDetails.endDate
         || !this.currentScreeningTenderDetails.startDate
      ) {
        return false
      }

      return true
    },
  },
  methods: {
    onItemClick(name) {
      if (name === 'builder') {
        this.showBuilderModal = true
      }
      if (name === 'offer') {
        this.showTenderDetailsModal = true
      }
    },
    onDialogClose() {
      this.showBuilderModal = false
      this.showTenderDetailsModal = false
      EventBus.$emit('page-updated')
    },
  },
  components: {
    PageLoading,
    MissingItem,
    Dialog,
  },
  created() {
    EventBus.$on('open-modal', this.onItemClick)
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (
        !this.currentScreeningMetaDataLoaded
        || !this.currentScreeningBuildingsLoaded
        || !this.addressImagesLoaded
        || !this.currentScreeningLoaded
      ) {
        return
      }

      clearInterval(waitForLoad)
      this.isLoading = false
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .MissingThings
    position relative
    width 794px
    min-height 100px
    padding 20px 20px 10px
    background-color #fff
    margin 20px auto
    box-shadow $box_shadow_1
    @media print
      display none

  .InnerWrap
    display flex
    justify-content space-between
    flex-wrap wrap
</style>
