<template>
  <div class="TenderDetailsModal">
    <span class="Title">{{ mixWB('W: Detaljer for udbud') }}</span>

    <TenderDetailsForm
      :isButtonLoading="isButtonLoading"
      :buttonText="mixWB('SAVE')"
      @form-submit="onFormSubmit" />
  </div>
</template>

<script>
import TenderDetailsForm from '@/components/Forms/TenderDetailsForm.vue'

export default {
  name: 'TenderDetailsModal',
  data() {
    return {
      isButtonLoading: false,
    }
  },
  methods: {
    onFormSubmit(tenderDetails) {
      this.isButtonLoading = true
      this.$store.dispatch('setTenderDetails', tenderDetails)

      setTimeout(() => {
        this.$emit('pass-to-parent', {
          name: 'tender-details-selected',
        })
      }, 200)
    },
  },
  components: {
    TenderDetailsForm,
  },

}
</script>

<style lang="stylus" scoped>
  .TenderDetailsModal
    display block

  .Title
    modalTitle()
</style>
