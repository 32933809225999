<template>
  <div
    class="UnitPage">
    <div
      class="Page"
      v-for="(page, index) in unitPages.pages"
      :key="page.id">
      <div class="InnerPage js-inner-page">
        <h1
          v-if="index === 0"
          class="js-headline"
          id="buildings">4. {{ mixWB('UNITS') }}</h1>
        <div
          class="BuildingWrap"
          v-for="unit in page.units"
          :key="unit.id">
          <UnitSection :unit="unit" />
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UnitSection from '@/components/PDF/Tender/UnitSection.vue'
import Footer from '@/components/PDF/Footer.vue'

export default {
  name: 'UnitPage',
  computed: {
    ...mapGetters([
      'selectedUnits',
    ]),
    topUnits() {
      return this.selectedUnits.filter((unit) => !unit.hasParent())
    },
    unitPages() {
      return this.topUnits.reduce((prev, unit, index) => {
        if (index % 2 === 0) {
          prev.pages.push({
            id: prev.nextPageIndex,
            units: [],
          })
          prev.nextPageIndex += 1
        }

        prev.pages[prev.nextPageIndex - 1].units.push(unit)

        return prev
      }, { pages: [], nextPageIndex: 0 })
    },
  },
  components: {
    UnitSection,
    Footer,
  },
}
</script>

<style lang="stylus" scoped>
  .UnitPage
    display block

  .Page
    page()

  .InnerPage
    innerPageReport()

  .BuildingWrap
    margin-bottom 20px
</style>
