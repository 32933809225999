<template>
  <div class="UnitSection">
    <div class="Headline">
      <span>{{ unit.getTitle() }}</span>
    </div>

    <!-- Unit  -->
    <div class="BBR">
      <template v-if="unit.type !== 'building'">
        <div class="Row">
          <span>{{ mixWB('ADDRESS') }}</span>
          <span>{{ address }}</span>
        </div>
      </template>
      <template v-else>
        <div class="Left">
          <div class="Row">
            <span>{{ mixWB('CADASTRAL_NUMBER') }}</span>
            <span>{{ unit.getCadastralNumber() || '?' }}</span>
          </div>
          <div class="Row">
            <span>{{ mixWB('LAND_OWNER_ASSCOSIATION') }}</span>
            <span>
              {{
                unit.getLandOwnerAssosiationName() || '?'
              }}
            </span>
          </div>
          <div class="Row">
            <span>{{ mixWB('YEAR_BUILT') }}</span>
            <span>{{ unit.getYearBuilt() || '?' }}</span>
          </div>
          <div class="Row">
            <span>{{ mixWB('REMODELING_YEAR') }}</span>
            <span>{{ unit.getRemodelingYear() || '?' }}</span>
          </div>
        </div>
        <div class="Right">
          <div class="Row">
            <span>{{ mixWB('BUILT_AREA') }}</span>
            <span>{{ unit.getBuiltArea() || '0 ' + mixWB('M2') }}</span>
          </div>
          <div class="Row">
            <span>{{ mixWB('UTILIZED_ATTIC_AREA') }}</span>
            <span>{{ unit.getUtilizedAtticArea() || '0 ' + mixWB('M2') }}</span>
          </div>
          <div class="Row">
            <span>{{ mixWB('BASEMENT_AREA') }}</span>
            <span>{{ unit.getBasementArea() || '0 ' + mixWB('M2') }}</span>
          </div>
          <div class="Row">
            <span>{{ mixWB('TOTAL_LIVING_AREA') }}</span>
            <span>{{ unit.getTotalLivingArea() || '0 ' + mixWB('M2') }}</span>
          </div>
          <div class="Row">
            <span>{{ mixWB('TOTAL_BUSINESS_AREA') }}</span>
            <span>{{ unit.getTotalBusinessArea() || '0 ' + mixWB('M2') }}</span>
          </div>
        </div>
      </template>
    </div>

    <!-- Images -->
    <div class="ImageList">
      <div
        class="Image"
        v-for="image in unitImages"
        :key="image.base.path">
        <MultiImage
          :image="image" />
      </div>
    </div>
  </div>
</template>

<script>
import MultiImage from '@/components/Images/MultiImage.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UnitSection',
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'addressImages',
      'currentScreeningData',
    ]),
    unitImages() {
      return this.addressImages.units[this.unit.id].profile
    },
    projectAddress() {
      if (!this.currentScreeningData) {
        return {}
      }

      return this.currentScreeningData.address
    },
    address() {
      return `${
        this.projectAddress.address
      }, ${
        this.projectAddress.postalCode
      } ${
        this.projectAddress.city
      }`
    },
  },
  components: {
    MultiImage,
  },
}
</script>

<style lang="stylus" scoped>
  .UnitSection
    border 1px solid $color_grey_dark
    display flex
    flex-wrap wrap

  .Headline
    background-color $color_grey_dark
    padding 10px
    width 100%
    span
      color #fff

  .BBR
    display flex
    width 100%
    border-bottom 1px solid $color_grey_dark
    .Left
      width 50%
      border-right 1px solid $color_grey_dark
    .Right
      flex-grow 2
    .Row
      display flex
      &:nth-child(even)
        background-color $color_grey_lightest
      span
        padding 5px 10px
        &:first-child
          width 130px
        &:last-child
          flex-grow 2
    .Right
      span
        &:first-child
          width 160px

  .ImageList
    width 100%
    display flex
    justify-content center
    padding 20px
    .Image
      box(200px)
      margin 0 10px
      border 1px solid $color_grey_lightest
</style>
