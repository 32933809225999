<template>
  <div class="GeneralInformation">
    <PageTag :pageName="mixWB('GENERAL_INFORMATION')" />
    <PageActions
      :actions="[
        { text: mixWB('BUILDER'), id: 'builder' },
      ]"
      @builder="onBuilder" />

    <PageLoading
      v-if="isLoading"
      :isLoading="isLoading" />

    <div
      v-if="!isLoading"
      class="InnerPage js-inner-page">
      <div class="Section">
        <h1
          class="js-headline"
          id="inspection">1. {{ mixWB('W: Besigtigelse') }}</h1>
        <span class="FirstLine">{{ mixWB('W: For besigtigelse kontakt bygherre') }}:</span>
        <span class="Indent">{{ builder.fullName }}</span>
        <span class="Indent">{{ mixWB('W: Tlf') }}: {{ getBuilderPhoneNumber() }}</span>
        <span class="Indent">{{ mixWB('EMAIL') }}: {{ builder.email }}</span>
      </div>

      <div class="Section">
        <h1
          class="js-headline"
          id="about-the-project">2. {{ mixWB('W: Om projektet') }}</h1>
        <span>{{ getProjectDescription() }}</span>
        <br/>
        <span class="SectionTitle">{{ mixWB('CASE_NUMBER') }}: {{ caseNumber }}</span>
        <span
          v-if="personalSamplingID"
          class="SectionTitle">{{ mixWB('SAMPLING_ID') }}: {{ personalSamplingID }}</span>
      </div>

      <div class="Section">
        <h1
          class="js-headline"
          id="cadastral-map">3. {{ mixWB('CADASTRAL_MAP') }}</h1>
        <div
          v-if="addressCadastralMap.length"
          class="CadastralImageWrap">
          <MultiImage
            :image="addressCadastralMap[0]" />
        </div>
        <span
          v-else
          class="NoImageText">
          {{ mixWB('W: Kunne ikke finde matrikkelkort for denne adresse.') }}
        </span>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageTag from '@/components/PDF/PageTag.vue'
import PageLoading from '@/components/PDF/PageLoading.vue'
import MultiImage from '@/components/Images/MultiImage.vue'
import Footer from '@/components/PDF/Footer.vue'
import PageActions from '@/components/PDF/PageActions.vue'
import EventBus from '@/EventBus'

export default {
  name: 'GeneralInformation',
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters([
      'metaData',
      'buildings',
      'selectedBuildings',
      'addressImages',
      'addressImagesLoaded',
      'currentScreeningMetaDataLoaded',
      'currentScreeningBuildingsLoaded',
      'caseNumber',
      'personalSamplingID',
    ]),
    builder() {
      return this.metaData.builder || {}
    },
    addressCadastralMap() {
      if (!this.addressImages?.overview?.cadastralMap) {
        return []
      }

      return this.addressImages.overview.cadastralMap
    },
  },
  methods: {
    getBuilderPhoneNumber() {
      if (!this.builder.phoneNumber) {
        return ''
      }

      return this.mixFormatPhoneNumber(this.builder.phoneNumber)
    },
    getProjectDescription() {
      const buildingNumbers = this.selectedBuildings.reduce((prev, building, index) => {
        if (index === 0) {
          prev += building.data.Bygningsnr
          return prev
        }

        if (index === this.selectedBuildings.length - 1) {
          prev = `${ prev } ${ this.mixWB('AND') } ${ building.data.Bygningsnr }`
          return prev
        }

        prev = `${ prev }, ${ building.data.Bygningsnr }`
        return prev
      }, '')

      if (this.metaData.projectType === 'renovation') {
        return this.mixWB(`W: Nedrivning, sanering og klargøring til bygmester i forbindelse med forestående renovering i bygning ${ buildingNumbers }.`)
      }

      if (this.selectedBuildings.length === this.buildings.length) {
        return this.mixWB('W: Komplet nedrivning og sanering af alle bygninger samt klargøring til bygmester.')
      }

      return `Nedrivning og sanering af bygning ${ buildingNumbers }, samt klargøring til bygmester.`
    },
    onBuilder() {
      EventBus.$emit('open-modal', 'builder')
    },
  },
  components: {
    PageTag,
    PageLoading,
    MultiImage,
    Footer,
    PageActions,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (
        !this.currentScreeningMetaDataLoaded
        || !this.currentScreeningBuildingsLoaded
        || !this.addressImagesLoaded
      ) {
        return
      }

      clearInterval(waitForLoad)
      this.isLoading = false
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .GeneralInformation
    page()

  .InnerPage
    innerPageReport()

  .Section
    margin-bottom 30px
    .FirstLine
      margin-bottom 5px
    .Indent
      padding-left 10px

  .CadastralImageWrap
    box(500px)
    margin 0 auto
    padding-top 10px
  .NoImageText
    font-style italic
</style>
