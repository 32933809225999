<template>
  <div class="MakeAnOffer">
    <PageTag :pageName="mixWB('W: Giv et tilbud')" />
    <PageActions
      v-if="!isPageLoading"
      :actions="[
        { text: mixWB('BUILDER'), id: 'builder' },
        { text: mixWB('W: Detaljer for udbud'), id: 'tender-details' },
      ]"
      @builder="onBuilder"
      @tender-details="onTenderDetails" />

    <PageLoading
      v-if="isPageLoading" />

    <div
      v-if="!isPageLoading"
      class="InnerPage js-inner-page">
      <h1
        class="js-headline"
        id="make-an-offer">5. {{ mixWB('W: Giv et tilbud') }}</h1>

      <div class="Section Intro">
        <span>Der gives tilbud på følgende måde:</span>
        <ol>
          <li>Lav et tilbud som tager stilling til de krav som er nævnt under dette afsnit.</li>
          <li>
            Indkludér en reference til dette dokument:
            <span class="Document">{{ mixGetTenderTitle() }}</span>.
          </li>
          <li>Send tilbuddet på email til <span class="Email">{{ builder.email }}</span>.</li>
        </ol>
      </div>

      <div class="Section">
        <h2>Tilbuddet skal indeholde</h2>
        <template>
          <ol>
            <li v-if="selectedDetails.includes('TQ-1')">
              {{ mixWB('W: Anmeldelse af affald til kommunen.') }}
            </li>
            <li v-if="selectedDetails.includes('TQ-2')">
              Miljøsanering iht. Miljøscreenings- og Kortlægningsrapport: {{ mixGetReportTitle() }}.
              <ol>
                <li>{{ mixWB('W: Omfang af saneringsopgaver kan vurderes besigtigelse.') }}</li>
              </ol>
            </li>
            <li v-if="selectedDetails.includes('TQ-3')">
              Nedrivning, sortering samt bortkørsel af alt affald fra berørte bygninger.
              <ol>
                <li>Grunden afrettes og klargøres til bygmester.</li>
                <li>Vandrør markeres.</li>
                <li>Kloak afproppes og markeres.</li>
                <li>El-kabel graves frit og markeres.</li>
                <li>Optagning samt bortkørsel af div. belægninger/fliser.</li>
                <li>Dokumentation på udført sanering og korrekt bortskaffelse af byggeaffald.</li>
              </ol>
            </li>
            <li v-if="selectedDetails.includes('TQ-4')">
              Alle bortskaffelsesafgifter.
            </li>
            <li v-if="selectedDetails.includes('TQ-5')">
              Køreplader ved fortov.
              <ol>
                <li>Merpris ved behov for yderligere køreplader.</li>
              </ol>
            </li>
            <li
              class="FreeText"
              v-for="text in currentScreeningTenderDetails.otherTexts"
              :key="text">
              <span
                v-for="(line, index) in text.split('\n')"
                :key="index">{{ line }}</span>
            </li>
          </ol>
        </template>
      </div>

      <div class="Section Dates">
        <h2>Start og deadline</h2>
        <span>{{ startText }}</span>
        <span>{{ deadlineText }}</span>
      </div>

      <div class="Section">
        <h2>Tilbudsformat</h2>
        <span>En samlet fast pris for nedrivning og sanering.</span>
        <span>Tilbuddet skal være fast inkl. alle udgifter, afgifter og gebyrer.</span>
      </div>

      <div class="Section">
        <h2>Standardforbehold</h2>
        <span>Der tages forbehold for følgende ekstra arbejde:</span>
        <ol>
          <li>Slagger under hus.</li>
          <li>
            Yderligere forekomster af miljøskadelige stoffer.
            <ol>
              <li>
                Hvis der under nedrivningen opdages nye forekomster af miljøskadelige stoffer,
                der ikke er omfattet af Miljøscreenings- og Kortlægningsrapport:
                {{ mixGetReportTitle() }}, tages der straks kontakt til bygherre.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import PageActions from '@/components/PDF/PageActions.vue'
import { mapGetters } from 'vuex'
import PageLoading from '@/components/PDF/PageLoading.vue'
import EventBus from '@/EventBus'
import Footer from '@/components/PDF/Footer.vue'

export default {
  name: 'MakeAnOffer',
  data() {
    return {
      isPageLoading: true,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningLoaded',
      'currentScreeningMetaDataLoaded',
      'currentScreeningTenderDetails',
      'tenderQuestions',
      'metaData',
    ]),
    builder() {
      return this.metaData.builder || {}
    },
    selectedDetails() {
      if (!this.currentScreeningTenderDetails) {
        return []
      }
      return this.currentScreeningTenderDetails.selectedQuestionIDs
    },
    startText() {
      if (!this.currentScreeningTenderDetails || !this.currentScreeningTenderDetails.startDate) {
        return this.mixWB('W: Startdato mangler...')
      }
      const now = new Date().getTime()
      const start = new Date(this.currentScreeningTenderDetails.startDate)
      const startTime = start.getTime()

      if (now > startTime) {
        return 'Start: Muligt så snart der foreligger nødvendige tilladelser og anvisning af bygningsaffald.'
      }

      return `Start: Muligt fra d. ${
        this.mixGetDate(this.currentScreeningTenderDetails.startDate, 'LL')
      } såfremt der foreligger nødvendige tilladelser og anvisning af bygningsaffald.`
    },
    deadlineText() {
      if (!this.currentScreeningTenderDetails || !this.currentScreeningTenderDetails.endDate) {
        return this.mixWB('W: Deadline mangler...')
      }

      return `Deadline: Forventet d. ${
        this.mixGetDate(this.currentScreeningTenderDetails.endDate, 'LL')
      }, kan evt. aftales nærmere med bygherre.`
    },
  },
  methods: {
    onBuilder() {
      EventBus.$emit('open-modal', 'builder')
    },
    onTenderDetails() {
      EventBus.$emit('open-modal', 'offer')
    },
  },
  components: {
    PageTag,
    PageActions,
    PageLoading,
    Footer,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (!this.currentScreeningLoaded || !this.currentScreeningMetaDataLoaded) {
        return
      }

      clearInterval(waitForLoad)
      this.isPageLoading = false

      EventBus.$emit('page-loaded', 'makeAnOffer')
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .MakeAnOffer
    page()
    p
      padding-bottom 15px

  .InnerPage
    innerPageReport()

  .Section
    margin-bottom 20px
    ol
      margin-bottom 0
      li
        margin-bottom 0
    > ol
      > li
        margin-bottom 10px
    &.Intro
      ol
        li
          margin-bottom 0
        span
          display inline
        span.Document
          font-weight bold
        span.Email
          text-decoration underline
    &.Dates
      span
        padding-bottom 10px
        &:last-child
          padding-bottom 0
    .FreeText
      span
        margin-left 15px
        &:first-child
          margin-left 0
</style>
